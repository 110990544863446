// Licensed to the Apache Software Foundation (ASF) under one
// or more contributor license agreements.  See the NOTICE file
// distributed with this work for additional information
// regarding copyright ownership.  The ASF licenses this file
// to you under the Apache License, Version 2.0 (the
// "License"); you may not use this file except in compliance
// with the License.  You may obtain a copy of the License at
//
//   http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing,
// software distributed under the License is distributed on an
// "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
// KIND, either express or implied.  See the License for the
// specific language governing permissions and limitations
// under the License.
<template>
  <a-list
  size="small">
    <a-list-item v-for="(item, index) in this.accountDetails" :key="index">
      <strong>{{ $t('label.account.'+index) }}</strong>
      <br>{{ item ? item : "-" }}
      <br>
    </a-list-item>
  </a-list>
</template>

<script>
import api2 from '@/wpApi/api2'

export default {
  name: 'Account',
  data () {
    return {
      formLoading: false,
      accountDetails: []
    }
  },
  props: {
    tab: {
      type: String,
      default () {
        return ''
      }
    }
  },
  watch: {
    tab (newValue, oldValue) {
      console.log(newValue)
      if (newValue === 'account') {
        this.listAccountDetails()
      }
    }
  },
  mounted () {
    this.listAccountDetails()
  },
  methods: {
    listAccountDetails () {
      this.formLoading = true
      api2.GetAccountDetails(this.$route.params.id).then(response => {
        this.accountDetails = response.data
        this.formLoading = false
      }).catch(error => {
        this.$notification.error({
          message: 'Error getting Account Details',
          description: error.response.data
        })
        this.formLoading = false
      })
    }
  }
}
</script>
